<template>
  <div :class="{grayed:location.deleted_at} " class="green-border-box outer-box">
    <div class="inner-box">
      <div class="grey-logo-circle">
        <img
          class="img-in-circle"
          :src="location.store.image + '?w=150&q=50'"
          @error="imageLoadOnError"
        >
      </div>
      <span class="title" :title="location.store.name">
        <button
          type="button"
          class="btn-positive btn-edit"
          data-toggle="modal"
          data-target="#restorelocationpopup"
          @click="openRestoreLocationPopup(location.id)"
          v-if="location.deleted_at"
        >Restore</button>
        <button
          type="button"
          class="btn-positive btn-edit"
          data-toggle="modal"
          data-target="#editstorepopup"
          @click="openEditLocationPopup(location.id)"
          v-else
        >EDIT</button>
        {{location.store.name}}
      </span>
      <br>
      <span class="description" :title="location.address">{{location.address}}</span>
      <br>
      <div class="text-center">
        <span
          v-if="location.pickls_count > 0"
          class="allocated"
        >{{location.pickls_count}} Pickl{{(location.pickls_count > 1)?'s':''}} Allocated</span>
        <span class="allocated allocated-alert" v-else>No Pickls Allocated</span>
        <br>
        <span
          class
        >{{location.deleted_at?'Deleted On':'Created On:'}} {{location.date}} {{location.time}}</span>
        <br>
      </div>
    </div>
  </div>
</template>

<script >
export default {
  props: {
    location: Object
  },
  methods: {
    imageLoadOnError () {
      this.location.store.image = '/img/default_store_image.png'
    },
    openEditLocationPopup (locationId) {
      this.$emit('getLocationDetails', locationId)
    },
    openRestoreLocationPopup (locationId) {
      this.$emit('getLocationDetails', locationId)
    }
  }
}
</script>

<style scoped>
.outer-box {
  padding: 15px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 25px;
}

.inner-box {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
}

.outer-box .title,
.outer-box .description {
  padding: 0 0 0 80px;
  white-space: nowrap;
  width: 100%;
  line-height: 30px;
  box-sizing: border-box;
  overflow: visible;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.outer-box .title {
  font-weight: 500;
  font-size: 22px;
  color: #1f1f1f;
  padding-right: 72px;
}

.outer-box .description {
  font-size: 17px;
  color: black;
}

.btn-edit {
  font-size: 14px;
  line-height: 18px;
  padding: 3px 15px;
  font-weight: 500;
  color: black;
  position: absolute;
  right: 0;
  top: 0;
}

.allocated {
  font-weight: 500;
  color: #782b54;
  font-size: 15px;
  margin-top: 5px;
  display: inline-block;
}

.allocated-alert {
  color: #e64733;
}

@media only screen and (max-width: 991px) {
  .outer-box .title {
    height: 60px;
    white-space: normal;
    text-align: left;
    padding-right: 0;
  }

  .btn-edit {
    float: right;
    margin-left: 10px;
    position: static;
  }

  .outer-box .description {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .outer-box {
    text-align: left;
  }

  .outer-box .title {
    padding-left: 70px;
  }

  .btn-edit {
    position: static;
  }
}
.grayed {
  background: repeating-linear-gradient(
    45deg,
    #fff,
    #fff 20px,
    #ddd 0,
    #ddd 40px
  );
}
</style>
