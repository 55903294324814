<template>
  <div class="green-border-box in-padd white-bg">
    <div class="row">

      <div class="col-xs-12 col-md-12 sort-control">

        <span class="filter-control">Filter By
           <select class="filters" v-model="storeId">
            <option value="" selected>Select Store</option>
            <option v-for="store in stores" :key="store.id" :value="store.id">{{store.name}}</option>
          </select>
          <div class="filters">
            <input class="btn-select" placeholder="City" type="text" v-model="city">
            <input class="btn-select" type="text" placeholder="Zip Code" v-model="zipCode">
            <select v-model="stateId">
              <option value="" selected>Select State</option>
              <option v-for="state in states" :key="state.id" :value="state.id">{{state.name}}</option>
            </select>
          </div>
        </span>
        <div class="filters" style="margin-left:20px;">
          Sort By
          <select v-model="sort">
            <option value="id-DESC" selected>Created &#8595;</option>
            <option value="id-ASC">Created &#8593;</option>
            <option value="name-ASC">A-Z</option>
            <option value="name-DESC">Z-A</option>
            <option value="pickls_count-DESC" selected>Allocated Pickls &#8595;</option>
            <option value="pickls_count-ASC">Allocated Pickls &#8593;</option>
            <option value="deleted_at-DESC">Deleted Locations</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row" v-if="store_locations.length> 0">
      <div
        class="col-xs-12 col-sm-6 item-list"
        v-for="location in store_locations"
        :key="location.id"
      >
        <StoreLocationItem :location="location" @getLocationDetails="getLocationDetails"/>
      </div>
    </div>
    <h2
      v-if="store_locations.length==0 && !loadingLocations"
      style="text-align:center;"
    >No Store Locations Found in system!</h2>
    <h2 v-if="loadingLocations" style="text-align:center;">Loading Store Locations...</h2>
    <div
      class="text-center"
      v-if="hasMoreLocations && !loadingLocations"
      @click="getStoreLocations"
    >
      <button class="btn-positive">Load more</button>
    </div>
    <StoreLocationPopup
      :stores="stores"
      @refreshStoreLocationsList="reFilter"
      :editStoreFlag="false"
      :closeStoreEditPopup="closeStoreEditPopup"
      popupId="storelocationpopup"
    />
    <StoreLocationPopup
      :stores="stores"
      @refreshStoreLocationsList="reFilter"
      :locationDetails="locationDetails"
      :editStoreFlag="true"
      :closeStoreEditPopup="closeStoreEditPopup"
      popupId="editstorepopup"
    />
    <RestoreLocationPopup
      :stores="stores"
      @locationRestored="reFilter"
      :locationDetails="locationDetails"
      popupId="restorelocationpopup"
    />
    <DeleteLocationPopup
      :stores="stores"
      @locationDeleted="locationDeleted"
      :locationDetails="locationDetails"
      popupId="deletelocationpopup"
    />
  </div>
</template>

<script>
import StoreLocationItem from '@/components/SAdmin/StoreLocationItem.vue'
import StoreLocationPopup from '@/components/SAdmin/StoreLocationPopup.vue'
import RestoreLocationPopup from '@/components/SAdmin/RestoreLocationPopup'
import DeleteLocationPopup from '@/components/SAdmin/DeleteLocationPopup'
export default {
  name: 'Stores',
  data () {
    return {
      timer: 0,
      loadingStores: true,
      hasMoreStores: false,
      hasMoreLocations: false,
      loadingLocations: true,
      editStoreFlag: false,
      storeSearchQuery: '',
      city: '',
      zipCode: '',
      state: '',
      sort: 'pickls_count-DESC',
      rowsOffset: 0,
      rowsLimitPerRequest: 100,
      storesLimitPerPage: 1000,
      storesPageOffset: 0,
      stores: [],
      store_locations: [],
      locationDetails: {
        city: '',
        state: '',
        zip_code: '',
        longitude: '',
        latitude: '',
        image: '',
        address: '',
        store_id: ''
      },
      closeStoreEditPopup: false,
      storeId: '',
      stateId: '',
      states: []
    }
  },
  components: {
    StoreLocationItem,
    StoreLocationPopup,
    RestoreLocationPopup,
    DeleteLocationPopup
  },
  mounted () {
    this.getStores()
    this.getStoreLocations()
    this.getStates()
  },
  methods: {
    getStores () {
      this.loadingStores = true
      let q = { limit: this.storesLimitPerPage, offset: this.storesPageOffset, sort: 'name-ASC' }
      this.$http.get('stores', { params: q }).then(function (response) {
        if (this.storesLimitPerPage === q.limit && this.storesPageOffset === q.offset) {
          this.stores.push(...response.body.data)
          if (this.stores.length < response.body.count) {
            this.storesPageOffset += this.storesLimitPerPage
            this.getStores()
          }
          this.loadingStores = false
        }
      })
    },
    getStates () {
      this.loadingStores = true
      this.$http.get('states').then(function (response) {
        this.states = response.body.data
      })
    },
    getStoreLocations () {
      this.loadingStores = true
      let q = {
        query: this.storeSearchQuery,
        sort: this.sort,
        city: this.city,
        state: this.state,
        storeId: this.storeId,
        stateId: this.stateId,
        zipCode: this.zipCode,
        limit: this.rowsLimitPerRequest,
        offset: this.rowsOffset,
        with: 'trashed'
      }
      this.$http.get('store_locations', { params: q }).then(function (response) {
        if (
          this.rowsLimitPerRequest === q.limit &&
          this.rowsOffset === q.offset &&
          this.storeSearchQuery === q.query &&
          this.sort === q.sort
        ) {
          this.rowsOffset += this.rowsLimitPerRequest
          this.store_locations.push(...response.body.data)
          if (this.store_locations.length === response.body.count) {
            this.hasMoreLocations = false
          } else {
            this.hasMoreLocations = true
          }
          this.loadingLocations = false
        }
      })
    },
    locationDeleted () {
      this.closeStoreEditPopup = true
      this.reFilter()
    },
    reFilter () {
      this.loadingLocations = true
      this.store_locations = []
      this.rowsOffset = 0
      this.getStoreLocations()
    },
    getLocationDetails (locationId) {
      this.closeStoreEditPopup = false
      let locationDetails = this.store_locations.filter(obj => {
        return obj.id === locationId
      })
      this.editStoreFlag = true
      this.locationDetails = locationDetails[0]
    }
  },

  watch: {
    sort () {
      this.loadingStores = true
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    },
    storeId () {
      this.loadingStores = true
      this.reFilter()
    },
    stateId () {
      this.loadingStores = true
      this.reFilter()
    },
    storeSearchQuery () {
      this.loadingStores = true
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    },
    city () {
      this.loadingStores = true
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    },
    zipCode () {
      this.loadingStores = true
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    },
    state () {
      this.loadingStores = true
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    }
  }
}
</script>

<style scoped>
.sort-control {
  text-align: right;
  padding: 6px 15px;
  margin-bottom: 20px;
}

.sort-control .btn-select {
  margin-bottom: 7px;
}

.filters {
  display: inline-block;
  width: auto;
  vertical-align: text-top;
}

@media only screen and (max-width: 991px) {
  .sort-control {
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .filter-control {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .filter-control {
    margin-right: 0;
    width: 100%;
    margin-bottom: 8px;
    display: inline-block;
  }

  .item-list >>> .outer-box span {
    white-space: normal;
    word-wrap: break-word;
  }
}
</style>
