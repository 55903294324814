var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "green-border-box outer-box",
      class: { grayed: _vm.location.deleted_at }
    },
    [
      _c("div", { staticClass: "inner-box" }, [
        _c("div", { staticClass: "grey-logo-circle" }, [
          _c("img", {
            staticClass: "img-in-circle",
            attrs: { src: _vm.location.store.image + "?w=150&q=50" },
            on: { error: _vm.imageLoadOnError }
          })
        ]),
        _c(
          "span",
          { staticClass: "title", attrs: { title: _vm.location.store.name } },
          [
            _vm.location.deleted_at
              ? _c(
                  "button",
                  {
                    staticClass: "btn-positive btn-edit",
                    attrs: {
                      type: "button",
                      "data-toggle": "modal",
                      "data-target": "#restorelocationpopup"
                    },
                    on: {
                      click: function($event) {
                        return _vm.openRestoreLocationPopup(_vm.location.id)
                      }
                    }
                  },
                  [_vm._v("Restore")]
                )
              : _c(
                  "button",
                  {
                    staticClass: "btn-positive btn-edit",
                    attrs: {
                      type: "button",
                      "data-toggle": "modal",
                      "data-target": "#editstorepopup"
                    },
                    on: {
                      click: function($event) {
                        return _vm.openEditLocationPopup(_vm.location.id)
                      }
                    }
                  },
                  [_vm._v("EDIT")]
                ),
            _vm._v("\n      " + _vm._s(_vm.location.store.name) + "\n    ")
          ]
        ),
        _c("br"),
        _c(
          "span",
          {
            staticClass: "description",
            attrs: { title: _vm.location.address }
          },
          [_vm._v(_vm._s(_vm.location.address))]
        ),
        _c("br"),
        _c("div", { staticClass: "text-center" }, [
          _vm.location.pickls_count > 0
            ? _c("span", { staticClass: "allocated" }, [
                _vm._v(
                  _vm._s(_vm.location.pickls_count) +
                    " Pickl" +
                    _vm._s(_vm.location.pickls_count > 1 ? "s" : "") +
                    " Allocated"
                )
              ])
            : _c("span", { staticClass: "allocated allocated-alert" }, [
                _vm._v("No Pickls Allocated")
              ]),
          _c("br"),
          _c("span", {}, [
            _vm._v(
              _vm._s(_vm.location.deleted_at ? "Deleted On" : "Created On:") +
                " " +
                _vm._s(_vm.location.date) +
                " " +
                _vm._s(_vm.location.time)
            )
          ]),
          _c("br")
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }