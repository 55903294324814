





































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { Brand } from '@/components/SAdmin/BrandItem.vue'

@Component
export default class DeactivateBrandPopup extends Vue {
  @Prop() popupId!: string
  @Prop() locationDetails!: any

  restoreLocation () {
    let popupCloseButton: HTMLElement = this.$refs.popupCloseButton as HTMLElement
    this.$store.dispatch('restoreLocation', { id: this.locationDetails.id }).then(() => {
      this.$emit('locationRestored')
      popupCloseButton.click()
    }, () => popupCloseButton.click())
  }
}
